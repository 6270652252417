/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/theme-css/bootstrap.css";
import "./src/theme-css/style.css";
import "./src/theme-css/animate.css";
import "./src/theme-css/style-magnific-popup.css";
import "./src/theme-css/pe-icon-7-stroke.css";
import "./src/theme-css/helper.css";
import "./src/theme-css/owl.carousel.min.css";
import "./src/theme-css/owl.theme.default.min.css";
